import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  styled,
  Chip,
  Stack,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import { PersonAddAlt1, PersonRemoveAlt1 } from '@mui/icons-material';
import { getImg } from '../utils/getImg';

const Tag = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),

  '&:nth-of-type(odd)': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
  },

  '&:nth-of-type(even)': {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.light,
  },
}));

const ClickableCard = styled(Card)`
  height: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const GridCard = ({
  title,
  tags,
  art_url,
  redirectTo,
  children,
  showFollowOption,
  isFollowed,
  onFollowClick,
}) => {
  tags = tags.slice(0, 2);
  let navigate = useNavigate();

  function handleRedirect() {
    navigate(redirectTo);
  }

  function followClickHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    onFollowClick();
  }

  const cardArt = getImg(art_url);

  return (
    <ClickableCard
      onClick={handleRedirect}
      sx={{
        backgroundColor: 'info.dark',
        ':hover': { backgroundColor: 'info.main' },
      }}
    >
      <CardMedia
        sx={{ height: 130, width: '100%' }}
        image={cardArt}
        title={title}
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            textAlign="left"
            gutterBottom
            variant="h3"
            fontSize="20px"
            fontWeight="bold"
          >
            {title}
          </Typography>
          {showFollowOption && (
            <Tooltip title={isFollowed ? 'Unfollow' : 'Follow'} arrow>
              <IconButton
                aria-label="expand row"
                onClick={followClickHandler}
                color="primary"
                sx={{ padding: 0 }}
              >
                {isFollowed ? <PersonRemoveAlt1 /> : <PersonAddAlt1 />}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        {children && <Box my={1}>{children}</Box>}
        <Stack direction="row" flexWrap={'wrap'}>
          {tags &&
            tags.map((tag) => (
              <Tag key={tag} label={tag} size="small">
                {tag}
              </Tag>
            ))}
        </Stack>
      </CardContent>
    </ClickableCard>
  );
};

GridCard.propTypes = {
  title: PropTypes.string,
  art_url: PropTypes.string,
  redirectTo: PropTypes.string,
  tags: PropTypes.array,
  showFollowOption: PropTypes.bool,
  isFollowed: PropTypes.bool,
  onFollowClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default GridCard;
