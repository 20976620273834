import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  InternalPageArt,
  InternalPageText,
  InternalPageMeta,
} from '../components/ui/page';
import { HowToReg, PersonAddAlt } from '@mui/icons-material';
import SetsSection from '../components/sections/SetsSection';
import TracksSection from '../components/sections/TracksSection';
import LabelsSection from '../components/sections/LabelsSection';
import IdsSection from '../components/sections/IdsSection';
import LoadingScreen from '../components/LoadingScreen';
import SocialLinks from '../components/SocialLinks';
import { useApi } from '../context/ApiProvider';
import { Link, Button, Stack } from '@mui/material';
import ShowMore from '../components/ShowMore';
import { getImg } from '../utils/getImg';
import TabsContainer from '../components/TabsContainer';
import { useSnackbar } from 'notistack';

const Artist = () => {
  const [loading, setLoading] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const api = useApi();
  const [artist, setArtist] = useState({
    sets: [],
    tracks: [],
    labels: [],
    remixes: [],
    ids: [],
  });
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    (async () => {
      const response = await api.get(`/artists/${id}`);
      if (response.ok) {
        setArtist(response.body);
        const is_followed = response.body.user_stats?.is_followed || false;
        setIsFollowed(is_followed);
      } else {
        console.log(response);
      }
      setLoading(false);
    })();
  }, [id]);

  const {
    art_url_xl,
    name,
    bio,
    founded_label,
    default_img_xl,
    set_count,
    label_count,
    remix_count,
    id_count,
    track_count,
  } = artist;

  const artistImg = getImg(default_img_xl);
  const avatarImg = getImg(art_url_xl);

  useEffect(() => {
    if (name) {
      const pageTitle = `${name} on KIP`;
      const pageImage = artistImg;
      document.title = pageTitle;
      document.head
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', pageTitle);
      document.head
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageImage);
    }

    return () => {
      document.title = 'KIP';
    };
  }, [name]);

  const tabTitles = [
    {
      title: `Sets`,
      count: set_count || 0,
      content: <SetsSection dataFrom={`/artists/${id}/sets`} key="Sets" />,
    },
    {
      title: `Tracks`,
      count: track_count || 0,
      content: (
        <TracksSection dataFrom={`/artists/${id}/tracks`} key="Tracks" />
      ),
    },
    {
      title: `Remixes`,
      count: remix_count || 0,
      content: (
        <TracksSection dataFrom={`/artists/${id}/remixes`} key="Remixes" />
      ),
    },
    {
      title: `IDs`,
      count: id_count || 0,
      content: <IdsSection dataFrom={`/artists/${id}/ids`} key="IDs" />,
    },
    {
      title: 'Labels',
      count: label_count || 0,
      content: (
        <LabelsSection dataFrom={`/artists/${id}/labels`} key="Labels" />
      ),
    },
  ].filter((tab) => tab.count > 0);

  async function handleFollowClick() {
    const response = await api.put(`/stats/artist/${id}/follow`);
    if (response.ok) {
      setIsFollowed(response.body.is_followed);
      handleFollowNotification(response.body.is_followed, 'followed');
    }
  }

  function handleFollowNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`${name} ${type}!`);
    } else {
      enqueueSnackbar(`${name} un${type}!`);
    }
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <PageHeader backgroundArt={artistImg} minHeight={300}>
        <InternalPageMeta>
          <InternalPageArt src={avatarImg || defaultArt} />
          <InternalPageText className="title">{name}</InternalPageText>
          {founded_label?.length && (
            <InternalPageText className="subtitle">
              Founder of{' '}
              {founded_label.map((label) => (
                <Link
                  key={label.id}
                  href=""
                  to={`/labels/${label.id}`}
                  sx={{
                    color: '#fff',
                    textDecorationColor: '#fff',
                    marginRight: 1,
                  }}
                >
                  {label.name}
                </Link>
              ))}
            </InternalPageText>
          )}
          <Stack direction={'row'} spacing={2} my={2}>
            <Button
              variant={!isFollowed ? 'contained' : 'outlined'}
              onClick={handleFollowClick}
              startIcon={!isFollowed ? <PersonAddAlt /> : <HowToReg />}
              sx={{
                maxWidth: '115px',
                backgroundColor: !isFollowed ? 'primary' : '',
                color: !isFollowed ? 'primary' : 'white',
                borderColor: !isFollowed ? 'primary' : 'white',
                ':hover': { borderColor: !isFollowed ? 'primary' : 'white' },
              }}
            >
              {!isFollowed ? 'Follow' : 'Following'}
            </Button>
            <SocialLinks data={artist} />
          </Stack>
          {bio && <ShowMore label="Bio" content={bio} />}
        </InternalPageMeta>
      </PageHeader>

      <TabsContainer
        tabTitles={tabTitles}
        tabContent={tabTitles.map((tab) => tab.content)}
      />
    </>
  );
};

export default Artist;
