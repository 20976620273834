import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../context/ApiProvider';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  InternalPageArtLabel,
  InternalPageText,
  InternalPageMeta,
} from '../components/ui/page';
import { HowToReg, PersonAddAlt } from '@mui/icons-material';
import LoadingScreen from '../components/LoadingScreen';
import SetsSection from '../components/sections/SetsSection';
import TracksSection from '../components/sections/TracksSection';
import ArtistsSection from '../components/sections/ArtistsSection';
import SocialLinks from '../components/SocialLinks';
import { Link, Button, Stack } from '@mui/material';
import ShowMore from '../components/ShowMore';
import { getImg } from '../utils/getImg';
import TabsContainer from '../components/TabsContainer';
import { useSnackbar } from 'notistack';

const Label = () => {
  const api = useApi();
  const [label, setLabel] = useState({
    sets: [],
    tracks: [],
    artists: [],
  });
  const [loading, setLoading] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      setLoading(true);
      const response = await api.get(`/labels/${id}`);
      if (response.ok) {
        setLabel(response.body);
        const is_followed =
          response.body.user_label_stats?.is_followed || false;
        setIsFollowed(is_followed);
      } else {
        console.log(response);
      }
      setLoading(false);
    })();
  }, [id]);

  const {
    name,
    bio,
    label_boss,
    art_url_xl,
    logo_url,
    sponsor_type,
    default_img_xl,
    set_count,
    artist_count,
    track_count,
  } = label;

  const labelImg = getImg(default_img_xl);
  const avatarImg = getImg(art_url_xl);

  useEffect(() => {
    if (name) {
      const pageTitle = `${name} on KIP`;
      const pageImage = labelImg || defaultArt;
      document.title = pageTitle;
      document.head
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', pageTitle);
      document.head
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageImage);
    }

    return () => {
      document.title = 'KIP';
    };
  }, [name]);

  const tabTitles = [
    {
      title: `Sets`,
      count: set_count || 0,
      content: <SetsSection dataFrom={`/labels/${id}/sets`} key="Sets" />,
    },
    {
      title: `Tracks`,
      count: track_count || 0,
      content: <TracksSection dataFrom={`/labels/${id}/tracks`} key="Tracks" />,
    },
    {
      title: `Artists`,
      count: artist_count || 0,
      content: (
        <ArtistsSection dataFrom={`/labels/${id}/artists`} key="Artists" />
      ),
    },
  ].filter((tab) => tab.count > 0);

  async function handleFollowClick() {
    const response = await api.put(`/stats/label/${id}/follow`);
    if (response.ok) {
      setIsFollowed(response.body.is_followed);
      handleFollowNotification(response.body.is_followed, 'followed');
    }
  }

  function handleFollowNotification(showAdded, type) {
    if (showAdded) {
      enqueueSnackbar(`${name} ${type}!`);
    } else {
      enqueueSnackbar(`${name} un${type}!`);
    }
  }
  //
  return (
    <>
      {loading && <LoadingScreen />}
      <PageHeader backgroundArt={labelImg} minHeight={300}>
        <InternalPageMeta>
          <InternalPageArtLabel src={avatarImg || logo_url || defaultArt} />
          <InternalPageText className="title">{name}</InternalPageText>
          {label_boss?.length && (
            <InternalPageText className="subtitle">
              Founded by{' '}
              {label_boss.map((boss) => (
                <Link
                  key={boss.id}
                  href=""
                  to={`/artists/${boss.id}`}
                  sx={{
                    color: '#fff',
                    textDecorationColor: '#fff',
                    marginRight: 1,
                  }}
                >
                  {boss.name}
                </Link>
              ))}
            </InternalPageText>
          )}
          <Stack direction={'row'} spacing={2} my={2}>
            <Button
              variant={!isFollowed ? 'contained' : 'outlined'}
              onClick={handleFollowClick}
              startIcon={!isFollowed ? <PersonAddAlt /> : <HowToReg />}
              sx={{
                maxWidth: '115px',
                backgroundColor: !isFollowed ? 'primary' : '',
                color: !isFollowed ? 'primary' : 'white',
                borderColor: !isFollowed ? 'primary' : 'white',
                ':hover': { borderColor: !isFollowed ? 'primary' : 'white' },
              }}
            >
              {!isFollowed ? 'Follow' : 'Following'}
            </Button>
            <SocialLinks data={label} />
          </Stack>
          {sponsor_type === 'label_meta' ? (
            bio && <ShowMore label="Bio" content={bio} />
          ) : (
            <ShowMore
              label="Curator profiles coming soon!"
              content="We are currently under development, thank you for your patience."
            />
          )}
        </InternalPageMeta>
      </PageHeader>

      <TabsContainer
        tabTitles={tabTitles}
        tabContent={tabTitles.map((tab) => tab.content)}
      />
    </>
  );
};

export default Label;
