import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TrackListItem from './TrackListItem';

import {
  useMediaQuery,
  useTheme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Grid,
} from '@mui/material';

const TrackListStyled = styled.div`
  padding: 80px 0;
  text-align: left;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
  }

  .label {
    font-size: 18px;
    opacity: 0.5;
  }

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 80px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .likes {
    text-align: end;
  }
`;

const TrackList = ({ tracks }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!tracks || tracks.length === 0) return '';

  return (
    <TrackListStyled>
      <Box>
        {isMobile ? (
          <Box sx={{ flexGrow: 1 }} my={3}>
            <Grid container spacing={2}>
              {tracks.map((track) => (
                <Grid item xs={12} lg={3} key={track.id}>
                  <TrackListItem key={track.id} track={track} asCard />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Artist</TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Label
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Released
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Genre
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Key
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    BPM
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Likes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tracks.map((track) => (
                  <TrackListItem key={track.id} track={track} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </TrackListStyled>
  );
};

TrackList.propTypes = {
  tracks: PropTypes.array,
};

export default TrackList;
