import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import defaultArt from '../assets/images/dj_kip_art.png';
import SetsTable from '../components/SetsTable';
import LoadMoreBtn from '../components/LoadMoreBtn';
import LoadingScreen from '../components/LoadingScreen';
import ListFilter from '../components/ListFilter';
import { useApi } from '../context/ApiProvider';
import { Typography, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const Sets = () => {
  const [searchParams] = useSearchParams();
  const [genreQueryParam, setGenreQueryParam] = useState(
    searchParams.get('genre')
  );
  const [sets, setSets] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState({
    order_by: 'set_meta.release_date',
    order_direction: 'desc',
    search: '',
    genre: '',
  });
  const api = useApi();

  useEffect(() => {
    if (genreQueryParam) {
      setPaginationConfig({ ...paginationConfig, genre: genreQueryParam });
    }
    loadSets();
  }, [paginationConfig]);

  const loadSets = async () => {
    if (loading) return;
    setLoading(true);

    const response = await api.get('/sets', paginationConfig);
    if (response.ok) {
      setSets(response.body.data);
      setPagination(response.body.pagination);
      setPage(1);
      setGenreQueryParam(null);
    } else {
      console.log(response);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    const response = await api.get('/sets', {
      ...paginationConfig,
      offset: 20 * page,
    });
    if (response.ok) {
      setSets([...sets, ...response.body.data]);
      setPagination(response.body.pagination);
      setPage(page + 1);
    } else {
      console.log(response);
    }
    setLoadingMore(false);
  };

  function onFilter(genre, sortBy, sortDirection) {
    if (genre === '' && genreQueryParam) {
      setGenreQueryParam('');
    }

    setPaginationConfig({
      ...paginationConfig,
      genre: genre,
      order_direction: sortDirection,
      order_by: `set_meta.${sortBy}`,
    });
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <PageHeader backgroundArt={defaultArt} minHeight={350}>
        <Typography variant="h3" fontWeight="bold" mt={4}>
          Discover{' '}
          <Typography
            variant="h3"
            component="span"
            color="primary"
            fontWeight="bold"
          >
            Sets
          </Typography>
        </Typography>
        <Typography mt={2}>
          {' '}
          Dive down the rabbit hole with musical journeys
        </Typography>
      </PageHeader>
      <Box sx={{ padding: { xs: '20px', lg: '30px 0' }, textAlign: 'center' }}>
        <Box my={2}>
          <ListFilter
            onFilter={onFilter}
            genreQueryParam={genreQueryParam}
            listType="sets"
          />
          <SetsTable
            items={sets}
            onTableInteraction={setPaginationConfig}
            tableInteractionConfig={paginationConfig}
            showGenreLink={false}
          />
        </Box>
        <LoadMoreBtn
          pagination={pagination}
          onLoadMoreClick={loadMore}
          loading={loadingMore}
        />
      </Box>
    </>
  );
};

export default Sets;
